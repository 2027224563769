<template>
  <v-container class="registration-user-container pt-10 px-3">
    <CategoryTitle :category="category" class="mt-0 my-lg-6" />
    <v-row class="mt-5 ">
      <v-col cols="12" sm="6">
        <v-card elevation="0" class="pa-5 d-flex flex-wrap">
          <v-card-title>{{ $t("register.user.cardYes.title") }}</v-card-title>
          <v-card-text class="text-body-0 primary--text font-weight-normal">{{
            $t("register.user.cardYes.text")
          }}</v-card-text>
          <v-img
            src="/img_layout/registration/registrazione-carta-fedelta.png"
          />
          <v-btn
            class="mt-auto"
            depressed
            color="primary"
            block
            x-large
            v-html="$t('register.user.cardYes.button')"
            :to="{
              name: 'RegistrationCard',
              params: {
                isRegisterWithCard: true
              }
            }"
          /> </v-card
      ></v-col>
      <v-col cols="12" sm="6">
        <v-card elevation="0" class="pa-5 d-flex flex-wrap">
          <v-card-title>{{ $t("register.user.cardNo.title") }}</v-card-title>
          <v-card-text class="text-body-0 primary--text font-weight-normal">{{
            $t("register.user.cardNo.text")
          }}</v-card-text>
          <v-img src="/img_layout/registration/carta-fedelta-conferma.jpg" />
          <v-btn
            class="mt-auto"
            depressed
            color="primary"
            block
            x-large
            v-html="$t('register.user.cardNo.button')"
            :to="{
              name: 'RegistrationNoCard',
              params: { type: 'NewUser', isRegisterWithCard: false }
            }"
          /> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  //color: var(--v-primary-base);
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 20px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      //  font-size: 30px;
      //   word-break: break-word;
      // line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 16px !important;
    }
    .v-card {
      height: auto;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";

export default {
  name: "RegistrationUser",
  data() {
    return {
      isSocialLogin: false
    };
  },
  mixins: [categoryMixins],
  components: { CategoryTitle }
};
</script>
