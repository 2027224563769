import { render, staticRenderFns } from "./RegistrationUser.vue?vue&type=template&id=7bd752c7&"
import script from "./RegistrationUser.vue?vue&type=script&lang=js&"
export * from "./RegistrationUser.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationUser.vue?vue&type=style&index=0&id=7bd752c7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports