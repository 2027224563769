var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"registration-user-container pt-10 px-3"},[_c('CategoryTitle',{staticClass:"mt-0 my-lg-6",attrs:{"category":_vm.category}}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"pa-5 d-flex flex-wrap",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("register.user.cardYes.title")))]),_c('v-card-text',{staticClass:"text-body-0 primary--text font-weight-normal"},[_vm._v(_vm._s(_vm.$t("register.user.cardYes.text")))]),_c('v-img',{attrs:{"src":"/img_layout/registration/registrazione-carta-fedelta.png"}}),_c('v-btn',{staticClass:"mt-auto",attrs:{"depressed":"","color":"primary","block":"","x-large":"","to":{
            name: 'RegistrationCard',
            params: {
              isRegisterWithCard: true
            }
          }},domProps:{"innerHTML":_vm._s(_vm.$t('register.user.cardYes.button'))}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"pa-5 d-flex flex-wrap",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("register.user.cardNo.title")))]),_c('v-card-text',{staticClass:"text-body-0 primary--text font-weight-normal"},[_vm._v(_vm._s(_vm.$t("register.user.cardNo.text")))]),_c('v-img',{attrs:{"src":"/img_layout/registration/carta-fedelta-conferma.jpg"}}),_c('v-btn',{staticClass:"mt-auto",attrs:{"depressed":"","color":"primary","block":"","x-large":"","to":{
            name: 'RegistrationNoCard',
            params: { type: 'NewUser', isRegisterWithCard: false }
          }},domProps:{"innerHTML":_vm._s(_vm.$t('register.user.cardNo.button'))}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }